@import "../../styles/fonts";
@import "../../styles/animations";


.animation-on-scroll3 {
    box-sizing: border-box;
    position: relative;
    display: block;
    top: 100px;
    opacity: 0;
    transition: top 0.8s ease-out, opacity 0.8s ease;
}

.animation-on-scroll3.show {
    top: 0px;
    opacity: 1;
}
