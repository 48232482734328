@import "../../styles/fonts";
@import "../../styles/animations";
@import "../../styles/colours";

.subscribe-popup {
    box-sizing: border-box;
    position: fixed;
    display: block;
    left: 0px;
    top:0px;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0 auto;
    z-index: 125;

    .subscribe-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 0 auto;
        background-color: $offWhiteColor;
        padding: 50px;
        width: 100%;
        max-width: 800px;
        border-radius: 30px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);

            .close-btn {
                box-sizing: border-box;
                position: absolute;
                display: block;
                padding: 10px;
                height: 48px;
                width: 48px;
                top: 30px;
                right: 30px;
                cursor: pointer;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='%232e2a39' viewBox='0 0 256 256'%3E%3Cpath d='M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z'%3E%3C/path%3E%3C/svg%3E");
            }


        .close-btn:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='%23e1b0c1' viewBox='0 0 256 256'%3E%3Cpath d='M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z'%3E%3C/path%3E%3C/svg%3E");
        }

        .heading {
            box-sizing: border-box;
            position: relative;
            display: block;
            text-align: center;
            margin: 0 auto;
            padding: 0;
            max-width: 817px;
            font-size: 49px;
            line-height: 64px;
            letter-spacing: 0.78px;
            color: $primaryColor;

            font-family: $poppins-semi-bold;
        }

        @media only screen and (max-width: 1100px) {
            .heading {
                font-size: 54px;
            }

            .close-btn {

                top: 30px;
                right: 30px;
            }
        }

        form {
            box-sizing: border-box;
            position: relative;
            display: block;
            padding: 30px 0 0 0;
            margin: 0 auto;
            max-width: 832px;

            .form-row {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 20px 0px 0px 0px;
                width: 100%;

                .text-input {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    padding: 12px 18px;
                    border: none;
                    outline: 1px solid #8a8a8a;
                    width: 100%;
                    font-size: 15px;
                    line-height: 24px;
                    letter-spacing: 1px;
                    border-radius: 10px;
                    background-color: #ffe5e5;
                }

                .text-input.disabled {
                    color: #757575;
                }

                .text-area {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    padding: 12px 18px;
                    font-family: $dm-sans;
                    min-height: 202px;
                    resize: none;
                    border: none;
                    outline: 1px solid #8a8a8a;
                    width: 100%;
                    font-size: 15px;
                    line-height: 24px;
                    letter-spacing: 1px;
                    border-radius: 10px;
                    background-color: #ffe5e5;
                }

                .text-area.disabled {
                    color: #757575;
                }

                .dropdown-label {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    padding-bottom: 20px;
                    font-size: 15px;
                    letter-spacing: 0.6px;
                    line-height: 29px;
                    color: #2E2A39BF;

                    input {
                        margin-right: 10px;
                    }

                    a {
                        color: $primaryColor;
                    }

                }

                select {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    border: none;
                    padding: 12px 18px;
                    height: 47px;
                    font-size: 15px;
                    line-height: 24px;
                    letter-spacing: 1px;
                    border-radius: 10px;
                    background-color: #ffe5e5;
                    outline: 1px solid #8a8a8a;
                    width: 100%;
                    color: #2E2A39BF;
                }

                select option:hover {
                    cursor: pointer;
                    }

                .form-col {
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    width: 50%;
                }

                .form-col1 {
                    padding: 0px 15px 0px 0px;
                }

                .form-col2 {
                    padding: 0px 0px 0px 15px;
                }

                @media only screen and (max-width: 720px) {
    
                    .form-col1, .form-col2 {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        margin: 20px 0px 0px 0px;
                        width: 100%;
                        padding: 0px;
                    }
                }
            }

            .form-row:first-child {
                margin: 0px;
            }

            .buttons-holder {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 50px 0 0 0;

                .submit-btn {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 0 auto;
                    padding: 10px 8px;
                    border-radius: 8px;
                    width: 100%;
                    max-width: 206px;
                    background-color: $primaryColor;
                    cursor: pointer;

                    input {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        font-family: $poppins-semi-bold;
                        font-size: 20px;
                        border: none;
                        outline: none;
                        border-radius: 10px;
                        width: 100%;
                        height: 100%;
                        background-color: $primaryColor;
                        color: $offWhiteColor;
                        cursor: pointer;
                    }
                }

                .submit-btn:hover {
                    background-color: #3d384b;
                    input {
                        background-color: #3d384b;
                    }
                }
            }

            .response {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin-top: 20px;

                p {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    font-size: 15px;
                    letter-spacing: 0.6px;
                    line-height: 29px;
                    max-width: 830px;
                    color: rgba(46, 42, 57, 0.7490196078);
                    margin: 0;
                    padding: 2px 0;
                }
            }
        }
    }

    @media only screen and (max-height: 850px) {
        
        .subscribe-block {
            overflow: auto;

            form {
                .form-row {
                    margin: 10px 0 0 0;

                    .dropdown-label {
                        padding-bottom: 5px;
                    }
                }

                .buttons-holder {
                    margin: 20px 0 0 0;

                    .submit-btn {
                        padding: 5px 4px;;
                    }
                }
            }

            .heading {
                font-size: 28px;
                line-height: 30px;
            }
        }
    }
}
