@import "../../styles/fonts";
@import "../../styles/animations";
@import "../../styles/colours";

.loader {
    box-sizing: border-box;
    position: relative;
    display: block;
    padding: 200px 0px;
    width: 100%;

    .circle {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 0px auto;
        border: 3px solid #F5F5F5;
        border-top: 3px solid #2e2a39;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        animation: spin 2s linear infinite;
    }
}