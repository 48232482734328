@import "../../styles/fonts";
@import "../../styles/animations";
@import "../../styles/colours";

.footer {
    box-sizing: border-box;
    position: relative;
    display: block;
    background-color: $primaryColor;

    .inner {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 0px auto;
        padding: 50px 0px;
        width: 100%;
        max-width: 1200px;

        .columns {
            box-sizing: border-box;
            position: relative;
            display: flex;
            padding: 0 30px;

        .col {
            box-sizing: border-box;
            position: relative;
            flex: 1;

            .col-heading {
                box-sizing: border-box;
                position: relative;
                display: block;
                font-family: $poppins-semi-bold;
                margin: 0 0 25px 0;
                color: #ffffff;
                font-size: 21px;
                line-height: 29px;
                letter-spacing: 0.78px;
            }

            @media only screen and (max-width: 720px) {
                .col-heading {
                    font-size: 28px;
                    line-height: 40px;
            }
        }

            .col-address {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 0px;
                color: #ffffff;

                p {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    font-size: 14px;
                    line-height: 26px;
                    letter-spacing: 0.6px;
                    margin: 0;
                    padding: 0;
                }
            }

            @media only screen and (max-width: 720px) {
                .col-address p {
                    font-size: 17px;
                    line-height: 36px;
            }
        }

            .col-newsletter {
                box-sizing: border-box;
                position: relative;
                display: block;
                color: $offWhiteColor;
                font-size: 14px;
                line-height: 26px;
                letter-spacing: 0.6px;
                margin: 0;
                padding: 0;
            }

            @media only screen and (max-width: 720px) {
                .col-newsletter {
                    font-size: 17px;
                    line-height: 36px;
            }
        }

        .subscribe-btn {
            box-sizing: border-box;
            position: relative;
            display: block;
            padding: 12px 0;
            margin-top: 20px;
            max-width: 200px;
            font-family: $poppins-semi-bold;
            font-size: 16px;
            line-height: 30px;
            border-radius: 10px;
            border: none;
            background-color: $secondaryColor;
            color: $primaryColor;
            text-decoration: none;
            text-align: center;
            cursor: pointer;
        }
    
        .subscribe-btn:hover {
            background-color: #fdd8d8;
            transition: background-color 0.3s;
        }

        @media only screen and (max-width: 720px) {
            .subscribe-btn {
                margin: 20px auto;
        }
    }

            .col-menu {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 0px;

                .item {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    left: 0px;
                    text-decoration: none;
                    white-space: nowrap;
                    transition: left 0.3s;
                    font-size: 14px;
                    line-height: 30px;
                    letter-spacing: 0.6px;
                    color: $offWhiteColor;

                    .name {
                        box-sizing: border-box;
                        position: relative;
                        display: inline-block;
                        vertical-align: middle;
                        padding: 0px;
                        cursor: pointer;
                    }
                }

                @media only screen and (max-width: 720px) {
                    .item {
                        font-size: 17px;
                        line-height: 42px;
                }
            }

                .item:hover {
                    left: 5px;
                }
            }
        }
    }


        @media only screen and (max-width: 720px) {
            .columns {
                box-sizing: border-box;
                position: relative;
                display: block;
                text-align: center;

                .col {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    padding-top: 50px;
            }
        }
    }

        .privacy-links {
            box-sizing: border-box;
            position: relative;
            display: block;
            text-align: center;
        }
        .privacy-links a {
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            color: $offWhiteColor;
            text-decoration: none;
            padding: 40px 20px 0 20px;
        }

        .privacy-links a:hover {
            color: $secondaryColor;
        }
    
        .icons {
            box-sizing: border-box;
            position: relative;
            display: block;
            text-align: center;
            padding: 30px 0 10px 0;

            .icon {
                box-sizing: border-box;
                position: relative;
                display: inline-block;
            }

            .icon1 {
                height: 44px;
                width: 44px;
                background-image: url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4_3904)'%3E%3Cpath d='M29.6154 13H14.3846C13.6196 13 13 13.6196 13 14.3846V29.6154C13 30.3804 13.6196 31 14.3846 31H29.6154C30.3804 31 31 30.3804 31 29.6154V14.3846C31 13.6196 30.3804 13 29.6154 13ZM19.2308 26.1538C19.2308 26.5363 18.921 26.8462 18.5385 26.8462C18.156 26.8462 17.8462 26.5363 17.8462 26.1538V20.6154C17.8462 20.2329 18.156 19.9231 18.5385 19.9231C18.921 19.9231 19.2308 20.2329 19.2308 20.6154V26.1538ZM18.5385 19.2308C17.9647 19.2308 17.5 18.7661 17.5 18.1923C17.5 17.6186 17.9647 17.1538 18.5385 17.1538C19.1122 17.1538 19.5769 17.6186 19.5769 18.1923C19.5769 18.7661 19.1122 19.2308 18.5385 19.2308ZM26.8462 26.1538C26.8462 26.5363 26.5363 26.8462 26.1538 26.8462C25.7713 26.8462 25.4615 26.5363 25.4615 26.1538V23.0385C25.4615 22.0822 24.687 21.3077 23.7308 21.3077C22.7745 21.3077 22 22.0822 22 23.0385V26.1538C22 26.5363 21.6902 26.8462 21.3077 26.8462C20.9252 26.8462 20.6154 26.5363 20.6154 26.1538V20.6154C20.6171 20.2329 20.9287 19.9248 21.3112 19.9265C21.6313 19.9283 21.9091 20.1489 21.9818 20.4613C23.4054 19.4956 25.343 19.8668 26.3088 21.2904C26.6584 21.8062 26.8462 22.4154 26.8462 23.0385V26.1538Z' fill='%23E1B0C1'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4_3904'%3E%3Crect width='18' height='18' fill='white' transform='translate(13 13)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
            }

            .icon1:hover {
                background-image: url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4_3904)'%3E%3Cpath d='M29.6154 13H14.3846C13.6196 13 13 13.6196 13 14.3846V29.6154C13 30.3804 13.6196 31 14.3846 31H29.6154C30.3804 31 31 30.3804 31 29.6154V14.3846C31 13.6196 30.3804 13 29.6154 13ZM19.2308 26.1538C19.2308 26.5363 18.921 26.8462 18.5385 26.8462C18.156 26.8462 17.8462 26.5363 17.8462 26.1538V20.6154C17.8462 20.2329 18.156 19.9231 18.5385 19.9231C18.921 19.9231 19.2308 20.2329 19.2308 20.6154V26.1538ZM18.5385 19.2308C17.9647 19.2308 17.5 18.7661 17.5 18.1923C17.5 17.6186 17.9647 17.1538 18.5385 17.1538C19.1122 17.1538 19.5769 17.6186 19.5769 18.1923C19.5769 18.7661 19.1122 19.2308 18.5385 19.2308ZM26.8462 26.1538C26.8462 26.5363 26.5363 26.8462 26.1538 26.8462C25.7713 26.8462 25.4615 26.5363 25.4615 26.1538V23.0385C25.4615 22.0822 24.687 21.3077 23.7308 21.3077C22.7745 21.3077 22 22.0822 22 23.0385V26.1538C22 26.5363 21.6902 26.8462 21.3077 26.8462C20.9252 26.8462 20.6154 26.5363 20.6154 26.1538V20.6154C20.6171 20.2329 20.9287 19.9248 21.3112 19.9265C21.6313 19.9283 21.9091 20.1489 21.9818 20.4613C23.4054 19.4956 25.343 19.8668 26.3088 21.2904C26.6584 21.8062 26.8462 22.4154 26.8462 23.0385V26.1538Z' fill='%23EFC8D1'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4_3904'%3E%3Crect width='18' height='18' fill='white' transform='translate(13 13)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
            }

            .icon2 {
                height: 44px;
                width: 44px;
                background-image: url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4_3907)'%3E%3Cpath d='M30.5439 17.5932C30.3904 17.0296 29.9573 16.5699 29.3795 16.3574C26.6249 15.3596 22.2406 15.3687 21.9995 15.3687C21.7584 15.3687 17.3742 15.3596 14.6195 16.3574C14.0417 16.5699 13.6086 17.0296 13.4551 17.5932C13.247 18.3453 12.9995 19.7199 12.9995 22.0002C12.9995 24.2806 13.247 25.6551 13.4551 26.4072C13.6086 26.9709 14.0417 27.4313 14.6195 27.6439C17.2584 28.5987 21.3888 28.6318 21.9465 28.6318H22.0525C22.6102 28.6318 26.743 28.5987 29.3795 27.6439C29.9573 27.4313 30.3904 26.9709 30.5439 26.4072C30.752 25.6536 30.9995 24.2806 30.9995 22.0002C30.9995 19.7199 30.752 18.3453 30.5439 17.5932ZM24.7493 22.2512L20.8922 24.6627C20.7443 24.7554 20.545 24.7177 20.4462 24.579C20.4109 24.5293 20.3924 24.4713 20.3924 24.4117V19.5888C20.3924 19.4222 20.5362 19.2873 20.7138 19.2873C20.7773 19.2873 20.8392 19.3046 20.8922 19.3378L24.7493 21.7493C24.8972 21.8412 24.9374 22.0289 24.8385 22.1675C24.8152 22.2007 24.7847 22.2293 24.7493 22.2512Z' fill='%23E1B0C1'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4_3907'%3E%3Crect width='18' height='18' fill='white' transform='translate(12.9995 13)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
            }

            .icon2:hover {
                height: 44px;
                width: 44px;
                background-image: url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4_3907)'%3E%3Cpath d='M30.5439 17.5932C30.3904 17.0296 29.9573 16.5699 29.3795 16.3574C26.6249 15.3596 22.2406 15.3687 21.9995 15.3687C21.7584 15.3687 17.3742 15.3596 14.6195 16.3574C14.0417 16.5699 13.6086 17.0296 13.4551 17.5932C13.247 18.3453 12.9995 19.7199 12.9995 22.0002C12.9995 24.2806 13.247 25.6551 13.4551 26.4072C13.6086 26.9709 14.0417 27.4313 14.6195 27.6439C17.2584 28.5987 21.3888 28.6318 21.9465 28.6318H22.0525C22.6102 28.6318 26.743 28.5987 29.3795 27.6439C29.9573 27.4313 30.3904 26.9709 30.5439 26.4072C30.752 25.6536 30.9995 24.2806 30.9995 22.0002C30.9995 19.7199 30.752 18.3453 30.5439 17.5932ZM24.7493 22.2512L20.8922 24.6627C20.7443 24.7554 20.545 24.7177 20.4462 24.579C20.4109 24.5293 20.3924 24.4713 20.3924 24.4117V19.5888C20.3924 19.4222 20.5362 19.2873 20.7138 19.2873C20.7773 19.2873 20.8392 19.3046 20.8922 19.3378L24.7493 21.7493C24.8972 21.8412 24.9374 22.0289 24.8385 22.1675C24.8152 22.2007 24.7847 22.2293 24.7493 22.2512Z' fill='%23EFC8D1'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4_3907'%3E%3Crect width='18' height='18' fill='white' transform='translate(12.9995 13)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
            }

            @media only screen and (max-width: 720px) {
                .icon1 {
                    height: 100px;
                    width: 100px;
                    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4_3904)'%3E%3Cpath d='M29.6154 13H14.3846C13.6196 13 13 13.6196 13 14.3846V29.6154C13 30.3804 13.6196 31 14.3846 31H29.6154C30.3804 31 31 30.3804 31 29.6154V14.3846C31 13.6196 30.3804 13 29.6154 13ZM19.2308 26.1538C19.2308 26.5363 18.921 26.8462 18.5385 26.8462C18.156 26.8462 17.8462 26.5363 17.8462 26.1538V20.6154C17.8462 20.2329 18.156 19.9231 18.5385 19.9231C18.921 19.9231 19.2308 20.2329 19.2308 20.6154V26.1538ZM18.5385 19.2308C17.9647 19.2308 17.5 18.7661 17.5 18.1923C17.5 17.6186 17.9647 17.1538 18.5385 17.1538C19.1122 17.1538 19.5769 17.6186 19.5769 18.1923C19.5769 18.7661 19.1122 19.2308 18.5385 19.2308ZM26.8462 26.1538C26.8462 26.5363 26.5363 26.8462 26.1538 26.8462C25.7713 26.8462 25.4615 26.5363 25.4615 26.1538V23.0385C25.4615 22.0822 24.687 21.3077 23.7308 21.3077C22.7745 21.3077 22 22.0822 22 23.0385V26.1538C22 26.5363 21.6902 26.8462 21.3077 26.8462C20.9252 26.8462 20.6154 26.5363 20.6154 26.1538V20.6154C20.6171 20.2329 20.9287 19.9248 21.3112 19.9265C21.6313 19.9283 21.9091 20.1489 21.9818 20.4613C23.4054 19.4956 25.343 19.8668 26.3088 21.2904C26.6584 21.8062 26.8462 22.4154 26.8462 23.0385V26.1538Z' fill='%23E1B0C1'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4_3904'%3E%3Crect width='18' height='18' fill='white' transform='translate(13 13)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
                }

                .icon1:hover {
                    height: 100px;
                    width: 100px;
                    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4_3904)'%3E%3Cpath d='M29.6154 13H14.3846C13.6196 13 13 13.6196 13 14.3846V29.6154C13 30.3804 13.6196 31 14.3846 31H29.6154C30.3804 31 31 30.3804 31 29.6154V14.3846C31 13.6196 30.3804 13 29.6154 13ZM19.2308 26.1538C19.2308 26.5363 18.921 26.8462 18.5385 26.8462C18.156 26.8462 17.8462 26.5363 17.8462 26.1538V20.6154C17.8462 20.2329 18.156 19.9231 18.5385 19.9231C18.921 19.9231 19.2308 20.2329 19.2308 20.6154V26.1538ZM18.5385 19.2308C17.9647 19.2308 17.5 18.7661 17.5 18.1923C17.5 17.6186 17.9647 17.1538 18.5385 17.1538C19.1122 17.1538 19.5769 17.6186 19.5769 18.1923C19.5769 18.7661 19.1122 19.2308 18.5385 19.2308ZM26.8462 26.1538C26.8462 26.5363 26.5363 26.8462 26.1538 26.8462C25.7713 26.8462 25.4615 26.5363 25.4615 26.1538V23.0385C25.4615 22.0822 24.687 21.3077 23.7308 21.3077C22.7745 21.3077 22 22.0822 22 23.0385V26.1538C22 26.5363 21.6902 26.8462 21.3077 26.8462C20.9252 26.8462 20.6154 26.5363 20.6154 26.1538V20.6154C20.6171 20.2329 20.9287 19.9248 21.3112 19.9265C21.6313 19.9283 21.9091 20.1489 21.9818 20.4613C23.4054 19.4956 25.343 19.8668 26.3088 21.2904C26.6584 21.8062 26.8462 22.4154 26.8462 23.0385V26.1538Z' fill='%23EFC8D1'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4_3904'%3E%3Crect width='18' height='18' fill='white' transform='translate(13 13)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
                }

                .icon2 {
                    height: 100px;
                    width: 100px;
                    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4_3907)'%3E%3Cpath d='M30.5439 17.5932C30.3904 17.0296 29.9573 16.5699 29.3795 16.3574C26.6249 15.3596 22.2406 15.3687 21.9995 15.3687C21.7584 15.3687 17.3742 15.3596 14.6195 16.3574C14.0417 16.5699 13.6086 17.0296 13.4551 17.5932C13.247 18.3453 12.9995 19.7199 12.9995 22.0002C12.9995 24.2806 13.247 25.6551 13.4551 26.4072C13.6086 26.9709 14.0417 27.4313 14.6195 27.6439C17.2584 28.5987 21.3888 28.6318 21.9465 28.6318H22.0525C22.6102 28.6318 26.743 28.5987 29.3795 27.6439C29.9573 27.4313 30.3904 26.9709 30.5439 26.4072C30.752 25.6536 30.9995 24.2806 30.9995 22.0002C30.9995 19.7199 30.752 18.3453 30.5439 17.5932ZM24.7493 22.2512L20.8922 24.6627C20.7443 24.7554 20.545 24.7177 20.4462 24.579C20.4109 24.5293 20.3924 24.4713 20.3924 24.4117V19.5888C20.3924 19.4222 20.5362 19.2873 20.7138 19.2873C20.7773 19.2873 20.8392 19.3046 20.8922 19.3378L24.7493 21.7493C24.8972 21.8412 24.9374 22.0289 24.8385 22.1675C24.8152 22.2007 24.7847 22.2293 24.7493 22.2512Z' fill='%23E1B0C1'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4_3907'%3E%3Crect width='18' height='18' fill='white' transform='translate(12.9995 13)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
                }

                .icon2:hover {
                    height: 100px;
                    width: 100px;
                    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4_3907)'%3E%3Cpath d='M30.5439 17.5932C30.3904 17.0296 29.9573 16.5699 29.3795 16.3574C26.6249 15.3596 22.2406 15.3687 21.9995 15.3687C21.7584 15.3687 17.3742 15.3596 14.6195 16.3574C14.0417 16.5699 13.6086 17.0296 13.4551 17.5932C13.247 18.3453 12.9995 19.7199 12.9995 22.0002C12.9995 24.2806 13.247 25.6551 13.4551 26.4072C13.6086 26.9709 14.0417 27.4313 14.6195 27.6439C17.2584 28.5987 21.3888 28.6318 21.9465 28.6318H22.0525C22.6102 28.6318 26.743 28.5987 29.3795 27.6439C29.9573 27.4313 30.3904 26.9709 30.5439 26.4072C30.752 25.6536 30.9995 24.2806 30.9995 22.0002C30.9995 19.7199 30.752 18.3453 30.5439 17.5932ZM24.7493 22.2512L20.8922 24.6627C20.7443 24.7554 20.545 24.7177 20.4462 24.579C20.4109 24.5293 20.3924 24.4713 20.3924 24.4117V19.5888C20.3924 19.4222 20.5362 19.2873 20.7138 19.2873C20.7773 19.2873 20.8392 19.3046 20.8922 19.3378L24.7493 21.7493C24.8972 21.8412 24.9374 22.0289 24.8385 22.1675C24.8152 22.2007 24.7847 22.2293 24.7493 22.2512Z' fill='%23EFC8D1'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4_3907'%3E%3Crect width='18' height='18' fill='white' transform='translate(12.9995 13)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
                }
            }
        }

        @media only screen and (max-width: 720px) {
            .icons {
                padding: 10px 0;
        }
    }

        .copyright {
            box-sizing: border-box;
            position: relative;
            display: block;
            color: #FDFBF7;
            font-size: 10px;
            text-align: center;
            letter-spacing: 0.7px;
            line-height: 17px;
        }

        @media only screen and (max-width: 720px) {
            .copyright {
                font-size: 16px;
        }
    }
    }
        @media only screen and (max-width: 720px) {
            .inner {
                padding: 0 0 30px 0;
        }
    }
}


