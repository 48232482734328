@import "../../styles/fonts";
@import "../../styles/animations";
@import "../../styles/colours";

.coach3 {
    box-sizing: border-box;
    position: relative;
    display: block;

    .breadcrumb-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        background-color: $secondaryColor;
        color: $primaryColor;
        height: 43px;

        p {
            box-sizing: border-box;
            position: relative;
            display: block;
            font-family: $poppins-semi-bold;
            font-size: 13px;
            letter-spacing: 0.6px;
            margin: 0 auto;
            max-width: 1200px;
            padding: 12px 25px;
        }
    }

    @media only screen and (max-width: 1300px) {
        .breadcrumb-block {
            padding-left: 32px;

            p {
                font-size: 16px;
                padding: 10px 25px;
            }
        }
    }

    .video-section {
        box-sizing: border-box;
        position: relative;
        display: block;
        background-image: url('../../media/imgs/coaches_bg.svg');
        background-attachment: fixed;
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 80px 30px;

    .video-container {
        box-sizing: border-box;
        position: relative;
        display: flex;
        justify-content: center;

    .video-block {
        box-sizing: border-box;
        position: relative;
        flex: 1;
        margin-right: 70px;
        width: 100%;
        height: 100%;
        max-width: 469px;
        max-height: 458px;

        .video-holder {
            box-sizing: border-box;
            position: relative;
            display: block;

            video {
                box-sizing: border-box;
                position: relative;
                display: block;
                border-radius: 10px;
            }

            .overlay {
                box-sizing: border-box;
                position: absolute;
                display: block;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
                background-image: url('../../media/imgs/gene_thumbnail.svg');
                cursor: pointer;
                transition: background 0.3s;
                border-radius: 10px;

                .play-icon {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 0px auto;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 83px;
                    height: 100px;
                    background-image: url("data:image/svg+xml,%3Csvg width='99' height='117' viewBox='0 0 99 117' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 8.45861V108.541C4.0455 111.051 6.12141 113.045 8.63526 112.999C9.43719 112.982 10.2221 112.755 10.9102 112.34L92.8548 62.2991C94.9534 61.0271 95.6302 58.3012 94.3563 56.2001C93.9866 55.5868 93.469 55.07 92.8548 54.7009L10.9102 4.6595C8.76038 3.35906 5.96216 4.04052 4.65974 6.1871C4.24456 6.87423 4.01706 7.6579 4 8.45861Z' stroke='%23E1B0C1' stroke-width='8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");                    background-repeat: no-repeat;
                    background-size: 100%;
                    background-position: center;
                }
            }

            .overlay:hover {
                opacity: 0.8;
            }
        }
    }

        .bio-block {
            box-sizing: border-box;
            position: relative;
            color: $primaryColor;
            flex: 1;
            max-width: 556px;
            
            span {
                font-weight: 700;
            }

            .heading {
                box-sizing: border-box;
                position: relative;
                display: block;
                font-size: 49px;
                line-height: 64px;
                letter-spacing: 0.78px;
                max-width: 300px;
                margin: 0;
                padding: 0 0 30px 0;
            }

            .paragraphs {
                box-sizing: border-box;
                position: relative;
                display: block;
                font-size: 15px;
                letter-spacing: 0.6px;
                max-width: 610px;
                line-height: 29px;
                margin: 0;
            }
        }

        @media only screen and (max-width: 900px) {

            .bio-block {
                padding: 40px 30px 0 30px;
                margin: 0 auto;
    
                .heading {
                    font-size: 36px;
                    line-height: 50px;
                    padding: 0 0 10px 0;
                    text-align: center;
                    margin: 0 auto;
                }
                .paragraphs {
                    padding-top: 0px;
                    text-align: center;
                    font-size: 17px;
                }
            }
        }
    }
}

    @media only screen and (max-width: 900px) {

        .video-section {
            box-sizing: border-box;
            position: relative;
            display: block;
            padding: 60px 20px;
        
        .video-container {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 0 auto;

            .video-block {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 0 auto;

                .video-holder {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    margin: 0 auto;
                    align-items: center;

                    .overlay {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 300px;
                        height: 307px;
                        background-size: cover;
                    }

                    video {
                        width: 300px;
                        height: 307px;
                        margin: 0 auto;
                        }
                    }
                }
            }

        }
    }

    .transform-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 80px 30px;
        min-height: 100%;
        background-color: $primaryColor;
        color: $offWhiteColor;

        span {
            font-weight: 700;
        }

        b {
            font-family: $poppins-black;
        }

        .heading {
            box-sizing: border-box;
            position: relative;
            display: block;
            font-size: 49px;
            line-height: 64px;
            letter-spacing: 0.78px;
            text-align: center;
            margin: 0;
        }

        .paragraphs {
            box-sizing: border-box;
            position: relative;
            display: block;
            text-align: center;
            font-size: 15px;
            max-width: 808px;
            line-height: 29px;
            letter-spacing: 0.6px;
            margin: 0 auto;
            padding-top: 30px;
        }

        .transform-btn {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 40px auto 0 auto;
            padding: 12px 0;
            max-width: 200px;
            font-family: $poppins-semi-bold;
            font-size: 16px;
            line-height: 30px;
            border-radius: 10px;
            border: none;
            background-color: #ffe5e5;
            color: $primaryColor;
            text-decoration: none;
            text-align: center;
        }
    
        .transform-btn:hover {
            background-color: #fdd8d8;
            transition: background-color 0.3s;
        }
    }

    @media only screen and (max-width: 720px) {

        .transform-block {
            padding: 50px 30px;
            
            .heading {
                font-size: 40px;
                line-height: 50px;
            }
            .paragraphs {
                padding-top: 20px;
                font-size: 17px;
            }
        }
    }

    .coaches-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 40px 0px 80px 0px;
        background-color: $secondaryColor;
        color: $primaryColor;

        .heading {
            box-sizing: border-box;
            position: relative;
            display: block;
            font-family: $poppins-semi-bold;
            font-size: 49px;
            line-height: 64px;
            letter-spacing: 0.78px;
            margin: 0;
            padding: 0px 0;
            text-align: center;
        }
    }

    @media only screen and (max-width: 720px) {

        .coaches-block {
            padding: 50px 30px;
            
            .heading {
                padding-bottom: 20px;
                font-size: 40px;
                line-height: 50px;
            }
        }
    }

    .offerings-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 40px 20px 80px 20px;
        background-color: $primaryColor;
        color: $offWhiteColor;

        .polaroids {
            .items {
                .item:nth-child(2), .item:nth-child(3) {
                    pointer-events: none;
                    background-color: #EBE6F8;
                    a {
                        color: #AAA1C1;
                    }
                    .arrow {
                        background-image: url("data:image/svg+xml,%3Csvg width='18' height='13' viewBox='0 0 19 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.439 1.05496C11.4827 0.948841 11.552 0.855181 11.6407 0.782314C11.7294 0.709448 11.8347 0.659637 11.9473 0.637315C12.0599 0.614994 12.1762 0.620855 12.286 0.654378C12.3958 0.6879 12.4955 0.748043 12.5765 0.829451L18.1452 6.39745C18.21 6.4621 18.2615 6.53891 18.2966 6.62347C18.3316 6.70802 18.3497 6.79867 18.3497 6.89022C18.3497 6.98177 18.3316 7.07242 18.2966 7.15697C18.2615 7.24153 18.21 7.31833 18.1452 7.38299L12.5765 12.951C12.4457 13.0817 12.2685 13.1551 12.0836 13.1551C11.8988 13.1551 11.7215 13.0817 11.5908 12.951C11.4601 12.8203 11.3866 12.643 11.3866 12.4582C11.3866 12.2734 11.4601 12.0961 11.5908 11.9655L15.972 7.58622H0.946095C0.761479 7.58622 0.584425 7.51289 0.453881 7.38237C0.323338 7.25184 0.25 7.07481 0.25 6.89022C0.25 6.70563 0.323338 6.5286 0.453881 6.39807C0.584425 6.26755 0.761479 6.19422 0.946095 6.19422H15.972L11.5908 1.81499C11.493 1.71762 11.4263 1.59337 11.3993 1.45804C11.3723 1.32272 11.3861 1.18241 11.439 1.05496Z' fill='%23AAA1C1'/%3E%3C/svg%3E%0A");
                    }
                }
            }
        }

        .heading {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 0;
            padding: 0px 0;
            text-align: center;
            font-family: $poppins-semi-bold;
            font-size: 49px;
            line-height: 64px;
            letter-spacing: 0.78px;
        }
    }

    @media only screen and (max-width: 720px) {

        .offerings-block {

            .heading {
                font-size: 40px;
                line-height: 50px;
            }
            padding: 50px 30px;
            
        }
    }

    .powered-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 0px 40px;
        background-color: $primaryColor;
        color: $offWhiteColor;
        background-color: #ffe5e5; /* For browsers that do not support gradients */
        background-image: linear-gradient(to top right, #EDC4CF, #EFC8D1, #FFE5E5);

        .paragraphs {
            box-sizing: border-box;
            position: relative;
            display: block;
            font-family: $poppins-semi-bold;
            color: $primaryColor;
            font-size: 28px;
            line-height: 38px;
            letter-spacing: 0.78px;
            text-align: center;
            max-width: 830px;
            margin: 0 auto;
            padding-top: 40px;
            padding-bottom: 50px;
            
            a {
                color: $primaryColor;
            }
        }
    }
    
    @media only screen and (max-width: 720px) {
    
        .powered-block {
            .paragraphs {
                font-size: 20px;
                line-height: 34px;
            }
        }
    }
}
