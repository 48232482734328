@import "../../styles/fonts";
@import "../../styles/animations";
@import "../../styles/colours";

.services {
    box-sizing: border-box;
    position: relative;
    display: block;

    .services-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 80px 30px;
        background-image: url('../../media/imgs/our_services_bg.svg');
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: contain;

        span {
            font-weight: 700;
        }

        .heading {
            box-sizing: border-box;
            position: relative;
            display: block;
            font-size: 80px;
            line-height: 80px;
            letter-spacing: 0.78px;
            color: $primaryColor;
            text-align: center;
            margin: 0;
        }
    }

        @media only screen and (max-width: 720px) {

            .services-block {
                padding: 30px 30px;
    
                .heading {
                    font-size: 49px;
                    line-height: 70px;
                }
            }
        }

    .leadership-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 80px 30px;
        background-color: $primaryColor;
        color: $offWhiteColor;
        
        span {
            font-weight: 700;
        }

        .heading {
            box-sizing: border-box;
            position: relative;
            display: block;
            font-size: 49px;
            line-height: 64px;
            letter-spacing: 0.78px;
            color: $offWhiteColor;
            text-align: center;
            margin: 0 auto;
            max-width: 600px;
        }

        .paragraphs {
            box-sizing: border-box;
            position: relative;
            display: block;
            text-align: center;
            font-size: 15px;
            max-width: 520px;
            line-height: 29px;
            letter-spacing: 0.6px;
            margin: 0 auto;
            padding-top: 40px;
        }

        .learn-btn {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 40px auto 0 auto;
            padding: 12px 0;
            max-width: 200px;
            font-family: $poppins-semi-bold;
            font-size: 16px;
            line-height: 30px;
            border-radius: 10px;
            border: none;
            background-color: $secondaryColor;
            color: $primaryColor;
            text-decoration: none;
            text-align: center;
        }

        .learn-btn:hover {
            background-color: $offWhiteColor;
            transition: background-color 0.3s;
        }
    }

    @media only screen and (max-width: 720px) {

        .leadership-block {
            padding: 50px 30px;

            .heading {
                font-size: 40px;
                line-height: 50px;
            }

            .paragraphs {
                padding-top: 20px;
                font-size: 17px;
            }
        }
    }

    .coaches-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 40px 0px 80px 0px;
        background-color: $secondaryColor;
        color: $primaryColor;

        .heading {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 0;
            padding: 0px 0;
            text-align: center;
            font-family: $poppins-semi-bold;
            font-size: 49px;
            line-height: 64px;
            letter-spacing: 0.78px;
        }
    }

    @media only screen and (max-width: 720px) {

        .coaches-block {
            padding: 50px 30px;

            .heading {
                font-size: 40px;
                line-height: 50px;
            }
            
            .heading {
                padding-bottom: 20px;
            }
        }
    }

    .talent-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 80px 30px;
        background-image: url('../../media/imgs/talent_bg.svg');
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center top;
        min-height: 100%;

        span {
            font-weight: 700;
        }

        .headings  {
            box-sizing: border-box;
            position: relative;
            display: block;
            color: $primaryColor;
            text-align: center;
            margin: 0 auto;
            max-width: 600px;

            :nth-child(2) {
                color: $secondaryColor;
              }

              h2 {
                box-sizing: border-box;
                position: relative;
                display: block;
                font-size: 49px;
                line-height: 64px;
                letter-spacing: 0.78px;
                margin: 0;
              }
        }

        .paragraphs {
            box-sizing: border-box;
            position: relative;
            display: block;
            font-size: 15px;
            text-align: center;
            max-width: 543px;
            line-height: 29px;
            letter-spacing: 0.78px;
            margin: 0 auto;
            padding-top: 30px;
        }
    }

    @media only screen and (max-width: 720px) {

        .talent-block {
            padding: 50px 30px;

            .headings {
                h2 {
                    font-size: 40px;
                    line-height: 50px;
                }
            }

            .paragraphs {
                padding-top: 20px;
                font-size: 17px;
            }
        }
    }

    .virtual-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 80px 30px;
        background-color: $primaryColor;
        color: $offWhiteColor;
        
        span {
            font-weight: 700;
        }

        .headings  {
            box-sizing: border-box;
            position: relative;
            display: block;
            color: $offWhiteColor;
            text-align: center;
            margin: 0 auto;
            max-width: 600px;

            :nth-child(2) {
                color: $secondaryColor;
              }

              h2 {
                box-sizing: border-box;
                position: relative;
                display: block;
                font-size: 49px;
                line-height: 64px;
                letter-spacing: 0.78px;
                margin: 0;
              }
        }

        .paragraphs {
            box-sizing: border-box;
            position: relative;
            display: block;
            text-align: center;
            max-width: 543px;
            font-size: 15px;
            line-height: 29px;
            letter-spacing: 0.78px;
            margin: 0 auto;
            padding-top: 30px;
        }
    }

    @media only screen and (max-width: 720px) {

        .virtual-block {
            padding: 50px 30px;

            .headings {
                h2 {
                    font-size: 40px;
                    line-height: 50px;
                }
            }

            .paragraphs {
                padding-top: 20px;
                font-size: 17px;
            }
        }
    }

    .transform-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 80px 30px;
        background-image: url('../../media/imgs/transform_bg.svg');
        min-height: 100%;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        span {
            font-weight: 700;
        }

        b {
            font-family: $poppins-black;
        }

        .heading {
            box-sizing: border-box;
            position: relative;
            display: block;
            font-size: 49px;
            line-height: 64px;
            letter-spacing: 0.78px;
            color: $primaryColor;
            text-align: center;
            margin: 0;
        }

        .paragraphs {
            box-sizing: border-box;
            position: relative;
            display: block;
            text-align: center;
            font-size: 15px;
            max-width: 808px;
            line-height: 29px;
            letter-spacing: 0.6px;
            margin: 0 auto;
            padding-top: 30px;
        }

        .transform-btn {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 40px auto 0 auto;
            padding: 12px 0;
            max-width: 200px;
            font-family: $poppins-semi-bold;
            font-size: 16px;
            line-height: 30px;
            border-radius: 10px;
            border: none;
            background-color: #ffe5e5;
            color: $primaryColor;
            text-decoration: none;
            text-align: center;
        }
    
        .transform-btn:hover {
            background-color: #fdd8d8;
            transition: background-color 0.3s;
        }
    }
        @media only screen and (max-width: 720px) {

            .transform-block {
                padding: 50px 30px;

                .heading {
                    font-size: 40px;
                    line-height: 50px;
                }

                .paragraphs {
                    padding-top: 20px;
                }
            }
        }


        
        .powered-block {
            box-sizing: border-box;
            position: relative;
            display: block;
            padding: 0px 40px;
            background-color: $primaryColor;
            color: $offWhiteColor;
            background-color: #ffe5e5; /* For browsers that do not support gradients */
            background-image: linear-gradient(to top right, #EDC4CF, #EFC8D1, #FFE5E5);
    
            .paragraphs {
                box-sizing: border-box;
                position: relative;
                display: block;
                font-family: $poppins-semi-bold;
                color: $primaryColor;
                font-size: 28px;
                line-height: 38px;
                letter-spacing: 0.78px;
                text-align: center;
                max-width: 830px;
                margin: 0 auto;
                padding-top: 40px;
                padding-bottom: 50px;

                a {
                    color: $primaryColor;
                }
            }
        }
        @media only screen and (max-width: 720px) {
    
            .powered-block {
                .paragraphs {
                    font-size: 20px;
                    line-height: 34px;
                }
            }
        }

}
