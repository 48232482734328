@import "../../styles/fonts";
@import "../../styles/animations";
@import "../../styles/colours";

.home {
    box-sizing: border-box;
    position: relative;
    display: block;

    .banner-video-block {
        box-sizing: border-box;
        position: relative;
        display: block;

            video {
              box-sizing: border-box;
              position: relative;
              display: block;
              width: 100%;
            }
          
          .video-controls {
              box-sizing: border-box;
              position: absolute;
              display: flex;
              padding: 0px 0px 15px 0px;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);

              .mute-btn {
                box-sizing: border-box;
                position: relative;
                display: inline-block;
                vertical-align: middle;
                border: none;
                width: 27px;
                height: 24px;
                background-color: transparent;
                background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2024.0.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20viewBox%3D%220%200%2027%2024%22%20style%3D%22enable-background%3Anew%200%200%2027%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cstyle%20type%3D%22text%2Fcss%22%3E%0D%0A%09.st0%7Bfill%3Anone%3Bstroke%3A%232e2a39%3Bstroke-width%3A2%3Bstroke-linecap%3Around%3Bstroke-linejoin%3Around%3B%7D%0D%0A%09.st1%7Bfill%3A%232e2a39%3B%7D%0D%0A%3C%2Fstyle%3E%0D%0A%3Ctitle%3EAsset%2041%3C%2Ftitle%3E%0D%0A%3Cg%20id%3D%22Layer_2_1_%22%3E%0D%0A%09%3Cg%20id%3D%22Layer_1-2%22%3E%0D%0A%09%09%3Cline%20class%3D%22st0%22%20x1%3D%2216%22%20y1%3D%227.2%22%20x2%3D%2226%22%20y2%3D%2217.2%22%2F%3E%0D%0A%09%09%3Cline%20class%3D%22st0%22%20x1%3D%2226%22%20y1%3D%227.2%22%20x2%3D%2216%22%20y2%3D%2217.2%22%2F%3E%0D%0A%09%09%3Cpath%20class%3D%22st1%22%20d%3D%22M11.8%2C0.4c-0.3-0.1-0.5-0.2-0.8-0.2c-0.5%2C0-0.9%2C0.2-1.3%2C0.5L4.6%2C5H2C0.9%2C5%2C0%2C5.9%2C0%2C7v10c0%2C1.1%2C0.9%2C2%2C2%2C2h2.6%0D%0A%09%09%09l5.1%2C4.3c0.6%2C0.5%2C1.4%2C0.6%2C2.1%2C0.3c0.7-0.3%2C1.2-1%2C1.2-1.8V2.2C13%2C1.4%2C12.5%2C0.7%2C11.8%2C0.4z%20M11%2C21.8L5.3%2C17H2V7h3.3L11%2C2.2V21.8z%22%2F%3E%0D%0A%09%3C%2Fg%3E%0D%0A%3C%2Fg%3E%0D%0A%3C%2Fsvg%3E%0D%0A);
                background-repeat: no-repeat;
                background-size: 27px 24px;
                cursor: pointer;
            }

            .mute-btn.unmuted {
              background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2024.0.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20viewBox%3D%220%200%2024%2024%22%20style%3D%22enable-background%3Anew%200%200%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cstyle%20type%3D%22text%2Fcss%22%3E%0D%0A%09.st0%7Bfill%3A%232e2a39%3B%7D%0D%0A%3C%2Fstyle%3E%0D%0A%3Ctitle%3EAsset%2040%3C%2Ftitle%3E%0D%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M19.1%2C20.2c-0.3%2C0-0.6-0.2-0.8-0.4c-0.3-0.5-0.2-1.1%2C0.3-1.4c0.8-0.5%2C1.5-1.2%2C2.1-2.1c1.1-1.6%2C1.5-3.6%2C1.1-5.6%0D%0A%09c-0.4-1.9-1.5-3.6-3.2-4.7c-0.5-0.3-0.6-0.9-0.3-1.4c0.3-0.5%2C0.9-0.6%2C1.4-0.3c4.3%2C2.9%2C5.5%2C8.7%2C2.6%2C13c-0.7%2C1-1.6%2C1.9-2.6%2C2.6%0D%0A%09C19.5%2C20.2%2C19.3%2C20.2%2C19.1%2C20.2z%22%2F%3E%0D%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M15.6%2C17.9c-0.3%2C0-0.6-0.2-0.8-0.4c-0.3-0.5-0.2-1.1%2C0.3-1.4c1-0.7%2C1.7-1.8%2C2-3c0.2-1.2%2C0-2.5-0.7-3.5%0D%0A%09c-0.3-0.5-0.8-0.9-1.3-1.3c-0.5-0.3-0.6-0.9-0.3-1.4c0.3-0.5%2C0.9-0.6%2C1.4-0.3c0.7%2C0.5%2C1.3%2C1.1%2C1.8%2C1.8c1%2C1.5%2C1.3%2C3.3%2C1%2C5%0D%0A%09c-0.3%2C1.8-1.4%2C3.3-2.8%2C4.3C15.9%2C17.9%2C15.8%2C17.9%2C15.6%2C17.9z%22%2F%3E%0D%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M11.8%2C0.4c-0.3-0.1-0.5-0.2-0.8-0.2c-0.5%2C0-0.9%2C0.2-1.3%2C0.5L4.6%2C5H2C0.9%2C5%2C0%2C5.9%2C0%2C7v10c0%2C1.1%2C0.9%2C2%2C2%2C2h2.6%0D%0A%09l5.1%2C4.3c0.6%2C0.5%2C1.4%2C0.6%2C2.1%2C0.3c0.7-0.3%2C1.2-1%2C1.2-1.8V2.2C13%2C1.5%2C12.5%2C0.7%2C11.8%2C0.4z%20M11%2C12v9.8L5.3%2C17H2V7h3.3L11%2C2.2V12z%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A);
              background-size: 24px 24px;
          }
          .volume-range {
            box-sizing: border-box;
            position: relative;
            // display: none;
            vertical-align: middle;
            margin: 0px 0px 0px 10px;
            width: 52px;
            background: transparent;
            -webkit-appearance: none;
        }

          .volume-range.show {
            display: inline-block;
        }
        
          .volume-range:focus {
              outline: none;
          }
        
          .volume-range::-webkit-slider-runnable-track {
              width: 100%;
              height: 2px;
              cursor: pointer;
              box-shadow: 0px 0px 1px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
              background: $primaryColor;
              border-radius: 0px;
              border: 0px solid rgba(0, 0, 0, 0);
          }
        
          .volume-range::-webkit-slider-thumb {
              box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
              border: 0px solid rgba(0, 0, 0, 0);
              height: 12px;
              width: 12px;
              border-radius: 6px;
              background: $primaryColor;
              cursor: pointer;
              -webkit-appearance: none;
              margin-top: -5px;
          }
          
        .volume-range:focus::-webkit-slider-runnable-track {
            background: $primaryColor;
        }
        
        .volume-range::-moz-range-track {
            width: 100%;
            height: 2px;
            cursor: pointer;
            box-shadow: 0px 0px 1px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
            background: $primaryColor;
            border-radius: 0px;
            border: 0px solid rgba(0, 0, 0, 0);
        }
        
        .volume-range::-moz-range-thumb {
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
            border: 0px solid rgba(0, 0, 0, 0);
            height: 12px;
            width: 12px;
            border-radius: 6px;
            background: $primaryColor;
            cursor: pointer;
        }
        
        .volume-range::-ms-track {
            width: 100%;
            height: 2px;
            cursor: pointer;
            background: transparent;
            border-color: transparent;
            color: transparent;
        }
        
        .volume-range::-ms-fill-lower {
            background: $primaryColor;
            border: 0px solid rgba(0, 0, 0, 0);
            border-radius: 0px;
            box-shadow: 0px 0px 1px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
        }
        
        .volume-range::-ms-fill-upper {
            background: $primaryColor;
            border: 0px solid rgba(0, 0, 0, 0);
            border-radius: 0px;
            box-shadow: 0px 0px 1px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
        }
        
        .volume-range::-ms-thumb {
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
            border: 0px solid rgba(0, 0, 0, 0);
            height: 12px;
            width: 12px;
            border-radius: 6px;
            background: $primaryColor;
            cursor: pointer;
            height: 2px;
        }
        
        .volume-range:focus::-ms-fill-lower {
            background: $primaryColor;
        }
        
        .volume-range:focus::-ms-fill-upper {
            background: $primaryColor;
        }
      }
    }

    .intro-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 80px 30px;
        background-color: $primaryColor;
        color: $offWhiteColor;
        min-height: 100%;

        span {
            font-weight: 700;
        }

        .heading {
            box-sizing: border-box;
            position: relative;
            display: block;
            font-size: 80px;
            line-height: 80px;
            letter-spacing: 0.78px;
            text-align: center;
            margin: 0;
        }

        .paragraphs {
            box-sizing: border-box;
            position: relative;
            display: block;
            padding-top: 30px ;
            text-align: center;
            max-width: 710px;
            line-height: 29px;
            font-size: 15px;
            letter-spacing: 0.6px;
            margin: 0 auto;
        }
    }

    @media only screen and (max-width: 720px) {

        .intro-block {
            padding: 50px 30px;
            
            .heading {
                font-size: 60px;
                line-height: 65px;
            }
            .paragraphs {
                padding-top: 20px;
                font-size: 17px;
            }
        }
    }

    .leader-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 80px 30px;
        background-image: url('../../media/imgs/home_banner.svg');
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 100%;
        
        span {
            font-weight: 700;
        }

        .heading {
            box-sizing: border-box;
            position: relative;
            display: block;
            font-size: 49px;
            line-height: 64px;
            letter-spacing: 0.78px;
            text-align: center;
            margin: 0 auto;
            max-width: 520px;
        }

        .paragraphs {
            box-sizing: border-box;
            position: relative;
            display: block;
            text-align: center;
            font-size: 15px;
            max-width: 530px;
            line-height: 29px;
            letter-spacing: 0.6px;
            margin: 0 auto;
            padding-top: 40px;
        }
    }


    @media only screen and (max-width: 720px) {

        .leader-block {
            padding: 50px 30px;

            .heading {
                font-size: 40px;
                line-height: 50px;
            }
            
            .paragraphs {
                padding-top: 20px;
                font-size: 17px;
            }
        }
    }

    .coaches-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 40px 0px 80px 0px;
        background-color: $secondaryColor;
        color: $primaryColor;

        .heading {
            box-sizing: border-box;
            position: relative;
            display: block;
            font-family: $poppins-semi-bold;
            font-size: 49px;
            line-height: 64px;
            letter-spacing: 0.78px;
            margin: 0;
            padding: 0px 0;
            text-align: center;
        }
    }

    @media only screen and (max-width: 720px) {

        .coaches-block {
            padding: 50px 30px;
            
            .heading {
                padding-bottom: 20px;
                font-size: 40px;
                line-height: 50px;
            }
        }
    }

    .promise-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 80px 30px;
        background-image: url('../../media/imgs/promise_bg.svg');
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 100%;

        span {
            font-weight: 700;
        }

        .heading {
            box-sizing: border-box;
            position: relative;
            display: block;
            font-size: 49px;
            line-height: 64px;
            letter-spacing: 0.78px;
            color: $primaryColor;
            text-align: center;
            margin: 0;
        }

        .paragraphs {
            box-sizing: border-box;
            position: relative;
            display: block;
            text-align: center;
            font-size: 15px;
            letter-spacing: 0.6px;
            line-height: 29px;
            padding-top: 30px;
            max-width: 530px;
            margin: 0 auto;
        }
    }

    @media only screen and (max-width: 720px) {

        .promise-block {
            padding: 50px 30px;
            
            .heading {
                font-size: 40px;
                line-height: 50px;
            }
            
            .paragraphs {
                padding-top: 20px;
                font-size: 17px;
            }
        }
    }

    .offerings-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 40px 20px 80px 20px;
        background-color: $primaryColor;
        color: $offWhiteColor;

        .polaroids {
            .items {
                .item:nth-child(2), .item:nth-child(3) {
                    pointer-events: none;
                    background-color: #EBE6F8;
                    a {
                        color: #AAA1C1;
                    }

                    .arrow {
                        background-image: url("data:image/svg+xml,%3Csvg width='18' height='13' viewBox='0 0 19 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.439 1.05496C11.4827 0.948841 11.552 0.855181 11.6407 0.782314C11.7294 0.709448 11.8347 0.659637 11.9473 0.637315C12.0599 0.614994 12.1762 0.620855 12.286 0.654378C12.3958 0.6879 12.4955 0.748043 12.5765 0.829451L18.1452 6.39745C18.21 6.4621 18.2615 6.53891 18.2966 6.62347C18.3316 6.70802 18.3497 6.79867 18.3497 6.89022C18.3497 6.98177 18.3316 7.07242 18.2966 7.15697C18.2615 7.24153 18.21 7.31833 18.1452 7.38299L12.5765 12.951C12.4457 13.0817 12.2685 13.1551 12.0836 13.1551C11.8988 13.1551 11.7215 13.0817 11.5908 12.951C11.4601 12.8203 11.3866 12.643 11.3866 12.4582C11.3866 12.2734 11.4601 12.0961 11.5908 11.9655L15.972 7.58622H0.946095C0.761479 7.58622 0.584425 7.51289 0.453881 7.38237C0.323338 7.25184 0.25 7.07481 0.25 6.89022C0.25 6.70563 0.323338 6.5286 0.453881 6.39807C0.584425 6.26755 0.761479 6.19422 0.946095 6.19422H15.972L11.5908 1.81499C11.493 1.71762 11.4263 1.59337 11.3993 1.45804C11.3723 1.32272 11.3861 1.18241 11.439 1.05496Z' fill='%23AAA1C1'/%3E%3C/svg%3E%0A");
                    }
                }
            }
        }

        .heading {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 0;
            padding: 0px 0;
            text-align: center;
            font-family: $poppins-semi-bold;
            font-size: 49px;
            line-height: 64px;
            letter-spacing: 0.78px;
        }
    }

    @media only screen and (max-width: 720px) {

        .offerings-block {

            .heading {
                font-size: 40px;
                line-height: 50px;
            }
            padding: 50px 30px;
            
        }
    }

    .transform-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 80px 30px;
        background-image: url('../../media/imgs/transform_bg.svg');
        min-height: 100%;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        span {
            font-weight: 700;
        }

        b {
            font-family: $poppins-black;
        }

        .heading {
            box-sizing: border-box;
            position: relative;
            display: block;
            font-size: 49px;
            line-height: 64px;
            letter-spacing: 0.78px;
            color: $primaryColor;
            text-align: center;
            margin: 0;
        }

        .paragraphs {
            box-sizing: border-box;
            position: relative;
            display: block;
            text-align: center;
            font-size: 15px;
            max-width: 808px;
            line-height: 29px;
            letter-spacing: 0.6px;
            margin: 0 auto;
            padding-top: 30px;
        }

        .transform-btn {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 40px auto 0 auto;
            padding: 12px 0;
            max-width: 200px;
            font-family: $poppins-semi-bold;
            font-size: 16px;
            line-height: 30px;
            border-radius: 10px;
            border: none;
            background-color: #ffe5e5;
            color: $primaryColor;
            text-decoration: none;
            text-align: center;
        }
    
        .transform-btn:hover {
            background-color: #fdd8d8;
            transition: background-color 0.3s;
        }
    }
        @media only screen and (max-width: 720px) {

            .transform-block {
                padding: 50px 30px;
                
                .heading {
                    font-size: 40px;
                    line-height: 50px;
                }
                .paragraphs {
                    padding-top: 20px;
                    font-size: 17px;
                }
            }
        }

    .powered-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 0px 40px;
        background-color: $primaryColor;
        color: $offWhiteColor;
        background-color: #ffe5e5; /* For browsers that do not support gradients */
        background-image: linear-gradient(to top right, #EDC4CF, #EFC8D1, #FFE5E5);

        .paragraphs {
            box-sizing: border-box;
            position: relative;
            display: block;
            font-family: $poppins-semi-bold;
            color: $primaryColor;
            font-size: 28px;
            line-height: 38px;
            letter-spacing: 0.78px;
            text-align: center;
            max-width: 830px;
            margin: 0 auto;
            padding-top: 40px;
            padding-bottom: 50px;

            a {
                color: $primaryColor;
            }
        }
    }

    @media only screen and (max-width: 720px) {
    
        .powered-block {
            .paragraphs {
                font-size: 20px;
                line-height: 34px;
            }
        }
    }
}
