@import "../../styles/fonts";
@import "../../styles/animations";
@import "../../styles/colours";

.terms {
    box-sizing: border-box;
    position: relative;
    display: block;

    .terms-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 0 30px 80px 30px;
        min-height: 100%;
        color: $primaryColor;
    

    .heading {
        box-sizing: border-box;
        position: relative;
        display: block;
        font-size: 49px;
        line-height: 64px;
        letter-spacing: 0.78px;
        margin: 20px auto;
    }

    .block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding-top: 30px ;
        max-width: 800px;
        line-height: 29px;
        font-size: 15px;
        letter-spacing: 0.6px;
        margin: 0 auto;

        a {
            color: $primaryColor;
        }
    }
}

    @media only screen and (max-width: 720px) {

        .paragraphs {
            padding-top: 20px;
            font-size: 17px;
        }
    }
}
