@import "../../styles/fonts";
@import "../../styles/animations";


.card-animation {
    box-sizing: border-box;
    position: relative;
    display: block;

}

.card-animation.show {
    .item:nth-child(1) {
        animation: cardFadeIn1 0.6s ease-in;
    }
    .item:nth-child(2) {
        animation: cardFadeIn1 0.9s ease-in;
    }
    .item:nth-child(3) {
        animation: cardFadeIn1 1.2s ease-in;
    }
    .item:nth-child(4) {
        animation: cardFadeIn1 1.5s ease-in;
    }
}
