@import "../../styles/fonts";
@import "../../styles/animations";
@import "../../styles/colours";

.polaroids {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: center;

    .item {
        box-sizing: border-box;
        position: relative;
        display: inline-block;
        margin: 30px 20px 0 20px;
        background-color: white;
        border-radius: 16px;

        a {
            text-decoration: none;
            color: $primaryColor;
        }
        
        .item-img img:nth-child(1) {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 0;
            padding: 0;
            max-height: 245px;
        }

        .item-img img:nth-child(2) {
            box-sizing: border-box;
            position: absolute;
            display: block;
            top: -12px;
            left: -14px;
        }

        .item-label {
            box-sizing: border-box;
            position: relative;
            padding: 10px 0 24px 0;
            margin: 0;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;


            h3 {
                box-sizing: border-box;
                position: relative;
                display: inline-block;
                margin: auto 20px auto 0;
                text-align: center;
                padding: 16px 0 0 0;
                font-family: $poppins-semi-bold;
                font-size: 15px;
                
            }

            .arrow {
                box-sizing: border-box;
                position: relative;
                display: inline-block;
                margin-top: 20px;
                margin-left: -8px;
                width: 18px;
                height: 13px;
                vertical-align: -1px;
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='13' viewBox='0 0 19 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.439 1.05496C11.4827 0.948841 11.552 0.855181 11.6407 0.782314C11.7294 0.709448 11.8347 0.659637 11.9473 0.637315C12.0599 0.614994 12.1762 0.620855 12.286 0.654378C12.3958 0.6879 12.4955 0.748043 12.5765 0.829451L18.1452 6.39745C18.21 6.4621 18.2615 6.53891 18.2966 6.62347C18.3316 6.70802 18.3497 6.79867 18.3497 6.89022C18.3497 6.98177 18.3316 7.07242 18.2966 7.15697C18.2615 7.24153 18.21 7.31833 18.1452 7.38299L12.5765 12.951C12.4457 13.0817 12.2685 13.1551 12.0836 13.1551C11.8988 13.1551 11.7215 13.0817 11.5908 12.951C11.4601 12.8203 11.3866 12.643 11.3866 12.4582C11.3866 12.2734 11.4601 12.0961 11.5908 11.9655L15.972 7.58622H0.946095C0.761479 7.58622 0.584425 7.51289 0.453881 7.38237C0.323338 7.25184 0.25 7.07481 0.25 6.89022C0.25 6.70563 0.323338 6.5286 0.453881 6.39807C0.584425 6.26755 0.761479 6.19422 0.946095 6.19422H15.972L11.5908 1.81499C11.493 1.71762 11.4263 1.59337 11.3993 1.45804C11.3723 1.32272 11.3861 1.18241 11.439 1.05496Z' fill='%232E2A39'/%3E%3C/svg%3E%0A");
            }
        }

        a:hover {
            h3 {
                margin: auto 24px auto 0;
                transition: margin 0.3s;
            }
            .margin-left {
                margin-left: -4px;
                transition: margin-left 0.3s;
            }
        }
    }
}