@import "../../styles/fonts";
@import "../../styles/animations";
@import "../../styles/colours";

.video-popup {
    box-sizing: border-box;
    position: fixed;
    display: block;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    margin: 0 auto;
    z-index: 125;
    overflow: visible;

    .heading {
        box-sizing: border-box;
        position: relative;
        display: block;
        text-align: center;
        margin: 0 auto;
        padding: 0 0 30px 0;
        max-width: 700px;
        font-size: 40px;
        line-height: 54px;
        letter-spacing: 0.78px;
        color: $primaryColor;
        font-family: $poppins-semi-bold;
    }

    @media only screen and (max-width: 920px) {
        .heading {
            padding: 0;
            font-size: 24px;
            line-height: 40px;
            max-width: 300px;
        }
    }

    .video-container {
        box-sizing: border-box;
        position: relative;
        display: block;
        margin: 0 auto;
        background-color: $offWhiteColor;
        padding: 70px 50px;
        width: 100%;
        max-width: 1000px;
        border-radius: 30px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);

        .close-btn {
            box-sizing: border-box;
            position: absolute;
            display: block;
            padding: 10px;
            height: 48px;
            width: 48px;
            top: 30px;
            right: 30px;
            cursor: pointer;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='%232e2a39' viewBox='0 0 256 256'%3E%3Cpath d='M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z'%3E%3C/path%3E%3C/svg%3E");
        }
    
        .close-btn:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='%23e1b0c1' viewBox='0 0 256 256'%3E%3Cpath d='M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z'%3E%3C/path%3E%3C/svg%3E");
        }

        // @media only screen and (max-width: 800px) {
        //     .close-btn {
        //         top: 80px;
        //     }
        // }

        .video-content {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 20px 0px 0px 0px;
            padding: 0px 0px 0px 380px;
            min-height: 203px;

            .video-block {
                box-sizing: border-box;
                position: absolute;
                display: block;
                left: 0px;
                top: 0px;
                width: 100%;
                max-width: 354px;

                video {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    width: 100%;
                }

                .overlay {
                    box-sizing: border-box;
                    position: absolute;
                    display: block;
                    top: 0px;
                    left: 0px;
                    height: 100%;
                    width: 100%;
                    cursor: pointer;
                    background-image: url('../../media/imgs/pop_vid_thumbnail.jpg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;

                    .play-icon {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        margin: 0px auto;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 62px;
                        height: 75px;
                        background-image: url("data:image/svg+xml,%3Csvg width='62' height='75' viewBox='0 0 99 117' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 8.45861V108.541C4.0455 111.051 6.12141 113.045 8.63526 112.999C9.43719 112.982 10.2221 112.755 10.9102 112.34L92.8548 62.2991C94.9534 61.0271 95.6302 58.3012 94.3563 56.2001C93.9866 55.5868 93.469 55.07 92.8548 54.7009L10.9102 4.6595C8.76038 3.35906 5.96216 4.04052 4.65974 6.1871C4.24456 6.87423 4.01706 7.6579 4 8.45861Z' stroke='%23E1B0C1' stroke-width='8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");                    background-repeat: no-repeat;
                        background-size: 100%;
                        background-position: center;
                    }
                }

                .overlay:hover {
                    opacity: 0.8;
                }
            }

            .video-text {
                box-sizing: border-box;
                position: relative;
                display: block;

                .paragraphs {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    font-size: 15px;
                    max-width: 808px;
                    line-height: 29px;
                    letter-spacing: 0.6px;
                    margin: 0 auto;

                    p {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        margin: 0px;
                    }
                }
            }
        }

        @media only screen and (max-width: 800px) {
            .video-content {
                padding: 0px;

                .video-block {
                    position: relative;
                    margin: 0px auto;
                }

                .video-text {
                    margin: 20px 0px 0px 0px;
                }
            }
        }

        .subscribe-btn {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 40px auto 0 auto;
            padding: 7px;
            font-family: $poppins-semi-bold;
            font-size: 16px;
            line-height: 30px;
            border-radius: 10px;
            border: none;
            background-color: #ffe5e5;
            color: $primaryColor;
            text-decoration: none;
            text-align: center;
            cursor: pointer;
        }
    
        .subscribe-btn:hover {
            background-color: #fdd8d8;
            transition: background-color 0.3s;
        }
    }
}
@media only screen and (max-width: 800px) {
    .video-popup {
        box-sizing: border-box;
        position: absolute;
        display: block;
        left: 0;
        top: 0;

        .video-container {
            top: 0%;
            -webkit-transform: translateY(0%);
            -ms-transform: translateY(0%);
            transform: translateY(0%);
        }
    }
}