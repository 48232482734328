@import "../../styles/fonts";
@import "../../styles/animations";
@import "../../styles/colours";

.contact {
    box-sizing: border-box;
    position: relative;
    display: block;

    .form-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 0px 30px;
        margin: 0;
        background-image: url('../../media/imgs/contact_bg.svg');
        background-attachment: fixed;
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;

        .heading {
            box-sizing: border-box;
            position: relative;
            display: block;
            text-align: center;
            margin: 0 auto;
            padding: 54px 0 0 0;
            font-size: 70px;
            max-width: 817px;
            line-height: 80px;
            font-family: $poppins-semi-bold;
            letter-spacing: 1px;
        }

        .paragraph {
            box-sizing: border-box;
            position: relative;
            display: block;
            margin: 0 auto;
            text-align: center;
            font-size: 15px;
            letter-spacing: 0.6px;
            line-height: 29px;
            padding: 30px 0 0 0;
            max-width: 830px;
            color: #2E2A39BF;
        }

        @media only screen and (max-width: 1100px) {
            .heading {
                font-size: 42px;
                line-height: 50px;
            }
        }

        form {
            box-sizing: border-box;
            position: relative;
            display: block;
            padding: 30px 0 50px 0;
            margin: 0 auto;
            max-width: 832px;

            .form-row {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 20px 0px 0px 0px;
                width: 100%;

                .text-input {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    padding: 12px 18px;
                    border: none;
                    outline: 1px solid #8a8a8a;
                    width: 100%;
                    font-size: 15px;
                    line-height: 24px;
                    letter-spacing: 1px;
                    border-radius: 10px;
                    background-color: #ffe5e5;
                }

                .text-input.disabled {
                    color: #757575;
                }

                .text-area {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    padding: 12px 18px;
                    font-family: $dm-sans;
                    min-height: 202px;
                    resize: none;
                    border: none;
                    outline: 1px solid #8a8a8a;
                    width: 100%;
                    font-size: 15px;
                    line-height: 24px;
                    letter-spacing: 1px;
                    border-radius: 10px;
                    background-color: #ffe5e5;
                }

                .text-area.disabled {
                    color: #757575;
                }

                .dropdown-label {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    font-size: 15px;
                    letter-spacing: 0.6px;
                    line-height: 29px;
                    color: #2E2A39BF;
                    padding-bottom: 10px;

                    input {
                        margin-right: 10px;
                    }

                    a {
                        color: $primaryColor;
                    }
                }

                select {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    border: none;
                    padding: 12px 18px;
                    height: 47px;
                    font-size: 15px;
                    line-height: 24px;
                    letter-spacing: 1px;
                    border-radius: 10px;
                    background-color: #ffe5e5;
                    outline: 1px solid #8a8a8a;
                    width: 100%;
                    color: #2E2A39BF;
                }

                select option:hover {
                    cursor: pointer;
                  }

                .form-col {
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    width: 50%;
                }

                .form-col1 {
                    padding: 0px 15px 0px 0px;
                }

                .form-col2 {
                    padding: 0px 0px 0px 15px;
                }

                @media only screen and (max-width: 720px) {
    
                    .form-col1, .form-col2 {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        margin: 20px 0px 0px 0px;
                        width: 100%;
                        padding: 0px;
                    }
                }
            }

            .form-row:first-child {
                margin: 0px;
            }

            .buttons-holder {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin: 30px 0px 0px 0px;

                .submit-btn {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    padding: 10px 8px;
                    border-radius: 8px;
                    width: 100%;
                    max-width: 206px;
                    background-color: $primaryColor;
                    cursor: pointer;

                    input {
                        box-sizing: border-box;
                        position: relative;
                        display: block;
                        font-family: $poppins-semi-bold;
                        font-size: 20px;
                        border: none;
                        outline: none;
                        border-radius: 10px;
                        width: 100%;
                        height: 100%;
                        background-color: $primaryColor;
                        color: $offWhiteColor;
                        cursor: pointer;
                    }
                }

                .submit-btn:hover {
                    background-color: #3d384b;
                    input {
                        background-color: #3d384b;
                    }
                }
            }

            .response {
                box-sizing: border-box;
                position: relative;
                display: block;
                margin-top: 20px;

                p {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    font-size: 15px;
                    letter-spacing: 0.6px;
                    line-height: 29px;
                    max-width: 830px;
                    color: #2E2A39BF;
                    margin: 0;
                    padding: 2px 0;
                }
            }
        }
    }

    .powered-block {
        box-sizing: border-box;
        position: relative;
        display: block;
        padding: 0px 40px;
        background-color: $primaryColor;
        color: $offWhiteColor;
        background-color: #ffe5e5; /* For browsers that do not support gradients */
        background-image: linear-gradient(to top right, #EDC4CF, #EFC8D1, #FFE5E5);

        .paragraphs {
            box-sizing: border-box;
            position: relative;
            display: block;
            font-family: $poppins-semi-bold;
            color: $primaryColor;
            font-size: 28px;
            line-height: 38px;
            letter-spacing: 0.78px;
            text-align: center;
            max-width: 830px;
            margin: 0 auto;
            padding-top: 40px;
            padding-bottom: 50px;

            a {
                color: $primaryColor;
            }
        }
    }

    @media only screen and (max-width: 720px) {
    
        .powered-block {
            .paragraphs {
                font-size: 20px;
                line-height: 34px;
            }
        }
    }
}
