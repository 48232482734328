@import "../../styles/fonts";
@import "../../styles/animations";


.animation-on-scroll2 {
    box-sizing: border-box;
    position: relative;
    display: block;
    top: 100px;
    opacity: 0;
    transition: top 0.5s ease-out, opacity 0.5s ease;
}

.animation-on-scroll2.show {
    top: 0px;
    opacity: 1;
}
